import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

//import placeholderImage from "../images/placeholder-640x480.png";
import analysisView from "../images/analysis-view.png";
import complexity from "../images/complexity.png";
import sharePublishView from "../images/share-and-publish.png";
import { HeaderSection, SectionBreak, InfoSection } from "../components/sections";

const LoginPage = () => (
    <Layout>
        <SEO title="Training and Collaboration" />
        <HeaderSection
            title="Training and Collaboration"
            subtitle="Get your team up to speed and in sync on what to do with genomic data."
        />
        <SectionBreak
            title="Graphical Web Interface Makes It Easy to Learn Tools"
            subtitle="Use the power or point-and-click interfaces to explore tools, options, and data."
        />
        <InfoSection
            image={complexity}
            facts={[
                {
                    title: "Easily Explore",
                    blurb: "Galaxy Pro comes with a large number of tools and each tool comes with help text and sample data. Explore different tools and tool options to see what works best on your data.",
                },
                {
                    title: "Different Tasks, Different Entry Points",
                    blurb: "Depending on your experience and task needs, choose to run individual tools and tweak tool parameters, or readily execute pre-existing composite workflows.",
                },
                {
                    title: "Faster Onboarding",
                    blurb: "Accessibility of Galaxy Pro makes it easy for new team members to jump right in to start learning about best practices adopted by the rest of the team. ",
                },
            ]}
        />
        <SectionBreak
            title="Share and Publish"
            subtitle="Just about every artifact in Galaxy Pro can be shared with specific users or published for better collaboration and transparency."
        />
        <InfoSection
            image={sharePublishView}
            imageOnLeft={false}
            facts={[
                {
                    title: "Document Directly in Galaxy Pro",
                    blurb: "Document your analysis procedures transparently and be ready to showcase your work. Galaxy Pro automatically tracks steps performed and tool parameters used so you can always recall what you did.",
                },
                {
                    title: "Share Knowledge",
                    blurb: "Share your analysis history or workflow with a colleague so they can easily learn how you solved a problem. They can extend the work and share it back with you.",
                },
                {
                    title: "Maintain a Single Source of Truth",
                    blurb: "Galaxy Pro provides ensures all the data and analysis steps are maintained in a single location, so all the team members have access to the same system and tools, avoiding localized discrepancies.",
                },
            ]}
        />
        <SectionBreak
            title="Bridge Role Boundaries"
            subtitle="Use the accessibility of Galaxy Pro to avoid team silos."
        />
        <InfoSection
            image={analysisView}
            facts={[
                {
                    title: "Empower Your Colleague",
                    blurb: "As a bioinformatician, start an analysis or do the first pass. Then, allow your biologist colleague to tweak it and tune it using the exact same environment.",
                },
                {
                    title: "Create Annotations and Documentation",
                    blurb: "Each history can be annotated and workflows can have an invocation report. Detail your thinking there as a form of live documentation.",
                },
                {
                    title: "Get Feedback and Collaborate",
                    blurb: "Share your procedures with the team to create a two-way street for learning from experience. ",
                },
            ]}
        />
    </Layout>
);

export default LoginPage;
